'use client'

import React from 'react'

import type { ContainerVariants } from './container.styles'

import { containerStyles } from './container.styles'

interface ContainerOwnProps {
  /** The child components that this component will wrap around. */
  children?: React.ReactNode

  /** The CSS class(es) to apply to the Container component. */
  className?: string
}

export interface ContainerProps extends ContainerOwnProps, ContainerVariants {}

export function Container({ size, className, children, ...rest }: ContainerProps) {
  const styles = containerStyles({ size, className })

  return (
    <div data-testid={'container'} className={styles} {...rest}>
      {children}
    </div>
  )
}
