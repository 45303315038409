import React, { Suspense } from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRootRouteWithContext, Outlet, ScrollRestoration } from '@tanstack/react-router'
import { useLocale } from 'react-aria-components'

import type { useHypertune } from '@fysioscout/hypertune/hypertune.react'
import type { QueryClient } from '@tanstack/react-query'

import { Toaster } from '@fysioscout/ui/status'
import { Text } from '@fysioscout/ui/typography'
import { useThemeContext } from '@fysioscout/widgets/theme'

import { env } from '@/config/env'
import { useTransitionBlockerStyle } from '@/hooks/use-transition-blocker-style'

export interface AppRouterContext {
  /** Query client used for the application. */
  queryClient: QueryClient

  /** Feature flags used for conditional rendering. */
  hypertune: ReturnType<typeof useHypertune>
}

export const Route = createRootRouteWithContext<AppRouterContext>()({
  component: RootLayout,
})

function RootLayout() {
  const { locale, direction } = useLocale()
  const { theme } = useThemeContext()

  React.useEffect(() => {
    document.documentElement.lang = locale
    document.documentElement.dir = direction
  }, [direction, locale])

  return (
    <div data-testid={'root-view'} lang={locale} dir={direction}>
      <ScrollRestoration />

      {env.VITE_API_MOCKING && <MockingIndicator />}
      {env.VITE_DISABLE_ANIMATIONS && <TransitionBlocker />}

      <Toaster
        theme={theme}
        toastOptions={{ classNames: { toast: 'gap-3' } }}
        position={'bottom-center'}
      />

      <Outlet />

      <Suspense fallback={null}>
        <TanStackRouterDevtools />
      </Suspense>

      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  )
}

function MockingIndicator() {
  return (
    <div className={'stack center bg-yellow-5 h-6 px-2'}>
      <Text size={'0'} className={'leading-none'} medium>
        Mocking active
      </Text>
    </div>
  )
}

function TransitionBlocker() {
  useTransitionBlockerStyle()

  return null
}

const LazyTanStackRouterDevtools = React.lazy(() =>
  import('@tanstack/router-devtools').then((res) => ({
    default: res.TanStackRouterDevtools,
  })),
)

export function TanStackRouterDevtools() {
  if (import.meta.env.PROD) {
    return null
  }

  return <LazyTanStackRouterDevtools />
}
