import React from 'react'
import { AlertCircleIcon, CircleCheckIcon, InfoIcon } from 'lucide-react'
import { match } from 'ts-pattern'

import type { CalloutVariants } from './callout.styles'

import { calloutStyles } from './callout.styles'

export interface CalloutProps extends CalloutVariants, React.HTMLAttributes<HTMLDivElement> {
  /** Optional class name to apply to the callout. */
  className?: string

  /**
   * Optional icon to display in the callout. If not provided, a default icon will be used based on
   * the `color` prop.
   */
  icon?: React.ReactNode
}

export function Callout({ children, className, size, variant, type, icon, ...rest }: CalloutProps) {
  const styles = calloutStyles({ size, variant, type, className })

  const fallbackIcon = match(type)
    .with('success', () => <CircleCheckIcon />)
    .with('warning', () => <AlertCircleIcon />)
    .with('error', () => <AlertCircleIcon />)
    .otherwise(() => <InfoIcon />)

  return (
    <div data-testid={'callout'} className={styles} {...rest}>
      {icon ?? fallbackIcon}
      <div className={'stack items-start'}>{children}</div>
    </div>
  )
}
